@use 'sass:math';
@import './vars';

$grid-columns: 8 !default;
$grid-gutter-width: 16px !default;
$grid-base-size: math.div($grid-gutter-width, 2);
$grid-half-size: math.div($grid-max-width, 2);
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
);

@import '~bootstrap/scss/bootstrap-grid';

.container,
.container-fluid {
  max-width: calc(100vw - #{$grid-gutter-width}) !important;
  @include media-breakpoint-up(md) {
    max-width: calc(100vw - #{$grid-gutter-width * 3}) !important;
  }
  // @include media-breakpoint-up(lg) {
  //   max-width: calc(100vw - #{$grid-gutter-width * 4}) !important;
  // }
  @include media-breakpoint-up(xl) {
    max-width: calc(100vw - #{$grid-gutter-width * 9}) !important;
  }
  @media (min-width: 1600px) {
    max-width: calc(1360px + #{$grid-gutter-width}) !important;
  }
}

.grid-demo {
  background: $color-subtl2;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  width: 100vw;
  z-index: 0;

  .container,
  .container-fluid {
    height: 100vh;

    .row {
      height: 100vh;

      > div {
        // &:nth-of-type(odd) {
        background: $color-white;
        border-left: $grid-base-size solid $color-subtl3;
        border-right: $grid-base-size solid $color-subtl3;
        height: 100vh;
        // }
      }
    }
  }
}

.grid-row {
  @include make-row();
}

.col-xl,
.col-large {
  @include make-col-ready();

  @include media-breakpoint-up(xs) {
    @include make-col($grid-columns, $grid-columns);
  }
}

.col-l,
.col-wide {
  @include make-col-ready();

  @include media-breakpoint-up(xs) {
    @include make-col($grid-columns, $grid-columns);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6, $grid-columns);
  }
}

.col-m,
.col-medium {
  @include make-col-ready();

  @include media-breakpoint-up(xs) {
    @include make-col($grid-columns, $grid-columns);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4, $grid-columns);
  }
}

.col-s,
.col-small {
  @include make-col-ready();

  @include media-breakpoint-up(xs) {
    @include make-col(4, $grid-columns);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(2, $grid-columns);
  }
}

.col-xs,
.col-tiny {
  @include make-col-ready();

  @include media-breakpoint-up(xs) {
    @include make-col(2, $grid-columns);
  }
  @include media-breakpoint-up(md) {
    @include make-col(1, $grid-columns);
  }
}
