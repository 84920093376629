html,
body {
  overscroll-behavior-y: none;
  overflow-x: hidden;
  width: 100vw;
}

html,
body,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
b,
strong,
em {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

form {
  // display: inline-block;
  // float: left;
  height: auto;
  position: relative;
  width: 100%;
}

button,
input,
select {
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

textarea {
  resize: none;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

ul {
  border: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  li {
    border: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: none;
  }
}

/*Inset border... why not working ??? */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

/* Chrome autofill background color remove hack */
input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  box-shadow: 0 0 0 1000px #fff inset;
}

img {
  border-radius: 8px;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-marker {
  z-index: 2 !important;
}

.floatImg {
  float: left;
}

.indent {
  padding: 0 0 1em 2em;
}
