@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import './mixins';

body,
html {
  line-height: 1;
}

body,
html,
button,
input,
textarea,
pre,
a {
  color: $color-dark;
  font-family: 'Noto Sans Regular', 'Noto Sans', sans-serif;
  font-weight: 400;
}

.hero {
  font-family: 'Noto Sans Medium', 'Noto Sans', sans-serif;
  font-weight: 400;
  color: $color-grey;
  font-size: $font-size-ml;
  letter-spacing: 0;
  line-height: 1.2;
  margin: $size-s 0;
}

h1 {
  font-family: 'Noto Sans Medium', 'Noto Sans', sans-serif;
  font-weight: 400;
  font-size: $font-size-m;
  letter-spacing: 0;
  line-height: 1.2;
  margin: $size-ss 0;
}

h2 {
  font-size: $font-size-s;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.3;
  margin: $size-xs 0;
}

.paragraph,
h3,
ul,
ol,
p,
li,
label,
legend,
input,
textarea,
select {
  font-size: $font-size-ss;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.4;
  margin: $size-ss 0 0;
}

.tiny,
small,
button,
q {
  font-size: $font-size-xxs;
  letter-spacing: 0;
  line-height: 1.2;
  margin: $size-xxs 0;
}

a {
  @include transition(color);
  // color: $color-blue;
  text-decoration: underline;
  &:hover {
    color: $color-grey;
  }
}

h3,
.bold,
b,
strong {
  font-weight: 700;
}

.italic,
i {
  font-style: italic;
}

.typo-highlight {
  color: $color-blue;
}

.typo-dark {
  color: $color-dark;
}

.typo-subtl1 {
  color: $color-grey;
}

.typo-subtl2 {
  color: $color-subtl2;
}

.typo-subtl1 {
  color: $color-subtl3;
}

hr {
  border: none;
  border-top: 1px solid $color-subtl2;
}

@include screen-m() {
  .hero {
    font-size: $font-size-xxl;
    margin: $size-xxs 0;
  }

  h1 {
    font-size: $font-size-l;
    margin: $size-s 0;
  }

  h2 {
    font-size: $font-size-sm;
    margin: $size-ss 0;
  }

  .paragraph,
  h3,
  p,
  li,
  select,
  textarea {
    font-size: $font-size;
    margin: $size-xs 0;
  }

  .tiny,
  small,
  button,
  label,
  legend,
  input,
  q {
    font-size: $font-size-xs;
  }
}

@include screen-xl() {
  .hero {
    font-size: $font-size-xxxl;
    margin: $size-sm 0;
  }

  h1 {
    font-size: $font-size-xl;
    margin: 18px 0;
  }

  h2 {
    font-size: $font-size-m;
    margin: 14px 0;
  }

  .paragraph,
  h3,
  p,
  li,
  select {
    font-size: $font-size-s;
    margin: 10px 0;
  }
}
